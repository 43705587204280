import navigation from "./components/navigation"
import { qs, qsa } from "./util/dom"
import { addVideoListeners } from "./util/video-helper"

// Totally ridiculous
// https://ilikekillnerds.com/2014/07/foundation-v5-unrecognized-expression-syntax-namespace-fix/
Foundation.global.namespace = ""

$(() => {
  $(document).foundation({
    tab: {
      deep_linking: true,
      scroll_to_content: false,
    },
  })

  $(".slick-slider").slick({
    dots: true,
    autoplay: true,
    initialSlide: 0,
    // autoplaySpeed: 5000,
    fade: true,
    cssEase: "linear",
  })

  $(".card-slider").slick({
    dots: true,
    autoplay: true,
    initialSlide: 0,
    // autoplaySpeed: 5000,
    fade: false,
    cssEase: "ease-in-out",
  })

  $(".project-slider").slick({
    dots: true,
    autoplay: true,
    initialSlide: 0,
    autoplaySpeed: 4000,
    // speed: 800,
    fade: false,
    cssEase: "ease-in-out",
  })

  $(".testimonial-slider").slick({
    dots: false,
    autoplay: true,
    // autoplaySpeed: 5000,
    initialSlide: 0,
    fade: false,
    cssEase: "ease-in-out",
  })

  $(".timeline-slider").slick({
    dots: true,
    autoplay: true,
    // autoplaySpeed: 5000,
    initialSlide: 0,
    fade: false,
    cssEase: "ease-in-out",
  })

  // let slideHome = $(".slick-slider--home").find(".slick-slide--first")
  // var slideProject = $('.project-slider').find('.slick-slide--first')
  const slideTestimonial = $(".testimonial-slider .slick-current")
  const slideProject = $(".project-slider .slick-current")
  const slideShop = $(".card-slider .slick-current")
  if (slideTestimonial.hasClass("arrow--white")) {
    $(".testimonial-slider .slick-arrow").addClass("white")
  }
  if (slideShop.hasClass("arrow--white")) {
    $(".card-slider  .slick-arrow").addClass("white")
  }
  if (slideProject.hasClass("arrow--white")) {
    $(".project-slider .slick-arrow").addClass("white")
  }

  $(".testimonial-slider").on(
    "afterChange",
    (_event, _slick, _currentSlide, _nextSlide) => {
      if (slideTestimonial.hasClass("arrow--white")) {
        $(".testimonial-slider .slick-arrow").addClass("white")
      }
      if (!slideTestimonial.hasClass("arrow--white")) {
        $(".testimonial-slider .slick-arrow").removeClass("white")
      }
    },
  )

  $(".card-slider").on(
    "afterChange",
    (_event, _slick, _currentSlide, _nextSlide) => {
      const colorPicker = $(".card-slider .slick-current")
      if (!colorPicker.hasClass("arrow--white")) {
        $(".card-slider .slick-arrow").removeClass("white")
        $(".card-slider .slick-dots").removeClass("white")
      } else {
        $(".card-slider .slick-arrow").addClass("white")
        $(".card-slider .slick-dots").addClass("white")
      }
    },
  )

  $(".project-slider").on(
    "afterChange",
    (_event, _slick, _currentSlide, _nextSlide) => {
      const slideProject = $(".project-slider .slick-current")
      if (!slideProject.hasClass("arrow--white")) {
        $(".project-slider .slick-arrow").removeClass("white")
        $(".project-slider .slick-dots").removeClass("white")
      } else {
        $(".project-slider .slick-arrow").addClass("white")
        $(".project-slider .slick-dots").addClass("white")
      }
    },
  )

  const languageSelect = document.querySelector(".language-select-btn")
  const languageChildren = document.querySelector(".meta-nav__children")

  // console.log(languageSelect);

  if (languageSelect) {
    languageSelect.addEventListener("click", () => {
      languageChildren.classList.toggle("active")
      document
        .querySelector(".language-select-btn i")
        .classList.toggle("rotated")
    })
  }

  // credits

  const creditsElement = document.querySelector("#credits")
  const creditsRollover = document.querySelector(".credits-rollover")
  //console.log(creditsElement);
  //console.log(creditsRollover);
  if (creditsElement) {
    creditsElement.addEventListener("mouseover", () => {
      creditsRollover.classList.add("show")
    })
    creditsElement.addEventListener("mouseout", () => {
      creditsRollover.classList.remove("show")
    })
    creditsElement.addEventListener("click", (e) => {
      e.preventDefault()
      creditsRollover.classList.toggle("toggle-show")
    })
  }

  // accordion

  const accordionEnterprise = $("#accordion-enterprise")
  const accordionBoxEnterprise = $("#accordion-box-enterprise")
  const accordionBanana = $("#accordion-banana")
  const accordionBoxBanana = $("#accordion-box-banana")

  const accordionWeAreHeader = $(".accordion--we-are")
  // let accordionWeAreBox = $("[accordion--box-we-are]")

  if (accordionWeAreHeader) {
    $(".accordion-box-close").click(function () {
      const $this = $(this)
      $this.parent().addClass("is-collapsed")
    })

    accordionWeAreHeader.click(function (_e) {
      const $this = $(this)
      if ($this.next().hasClass("is-collapsed")) {
        accordionWeAreHeader.each(function (_e) {
          if (!$(this).next().hasClass("is-collapsed")) {
            $(this).next().addClass("is-collapsed")
          }
          $this.next().removeClass("is-collapsed")
          // e.next().removeClass('is-collapsed')
        })
        // $this.next().slideUp(350);
      } else {
        $this
          .parent()
          .parent()
          .find(".accordion--we-are")
          .removeClass("is-collapsed")
        // $this.parent().parent().find('li .inner').slideUp(350);
        $this.next().toggleClass("is-collapsed")
        $(".accordion-box-close").next().toggleClass("is-collapsed")
        // $this.next().slideToggle(350);
      }
    })
  }

  if (accordionEnterprise) {
    accordionEnterprise.click(() => {
      accordionBoxEnterprise.toggleClass("is-collapsed")
    })
    accordionBanana.click(() => {
      accordionBoxBanana.toggleClass("is-collapsed")
    })
  }

  navigation()

  copyModalNewsletterForm()

  /*************************************************************************/
  $(document.body).on("submit", "#newsletter-modal", () => {
    $("#newsletter-modal").foundation("reveal", "close")
  })

  /*************************************************************************/

  function copyModalNewsletterForm() {
    // determine if we are on newsletter-form template (which adds the special class')
    const $mainContent = $(".main-content.newsletter-form")

    if ($mainContent.length > 0) {
      const $modalForm = $("#newsletter-modal")

      const $newRow = $(
        '<div class="alternating"><div class="row"><div class="large-12 columns"><div id="newsletter-inserted" class="content-form large-10 end prepend-top-2x append-bottom-2x"></div></div></div></div>',
      ).appendTo($mainContent)

      $newRow.find("#newsletter-inserted").html($modalForm.html())
      $newRow.find(".close-reveal-modal").remove()
      $newRow.find("h2").remove()
      $newRow.find(".lead").remove()
    }
  }

  const videoPopups = qsa(".ugb-video-popup")
  if (videoPopups) addVideoListeners(videoPopups)

  // save query string if querystring field is added with form designer
  const query_string_field = qs('.field-querystring input[type="hidden"]')
  if (query_string_field && window.location.search) {
    query_string_field.value = window.location.search
  }
})
