/*
 * Navigation
 */

export default () => {
  const maxHeight = 1000 // dropdown max-height

  //---- Initialize
  // set the dropdown-list visible
  $("li.has-children.active").addClass("visible")
  // rotate the arrow icon
  $("li.has-children.active i").addClass("rotated")

  //---- Event triggers
  $(".toggle-nav").on("click", toggleNav)
  $(".accordion-toggle").on("click", toggleOffcanvasDropdown)
  $(document).on("keyup", closeNav)
  $(".main-nav > li").hover(dropdownIn, dropdownOut)

  //---- show and hide offcanvas nav
  function toggleNav() {
    document.body.classList.toggle("show-nav")
  }

  //---- show and hide offcanvas dropdown lists
  function toggleOffcanvasDropdown(e) {
    e.preventDefault()
    $(this).parent().toggleClass("visible")
    $(this).children("i").toggleClass("rotated")
  }

  //---- close nav with 'esc'-key
  function closeNav(e) {
    if (e.keyCode === 27) {
      if (document.body.classList.contains("show-nav")) {
        // Assuming you used the function I made from the demo
        toggleNav()
      }
    }
  }

  //---- Show mainnav dropdown
  function dropdownIn() {
    const $container = $(this)
    const $list = $container.find("ul")
    const $anchor = $container.find("a")
    const height = $list.height() * 1.1 // make sure there is enough room at the bottom
    const multiplier = height / maxHeight // needs to move faster if list is taller

    // need to save height here so it can revert on mouseout
    $container.data("origHeight", $container.height())

    // so it can retain it's rollover color all the while the dropdown is open
    $anchor.addClass("hover")

    // make sure dropdown appears directly below parent list item
    $list.show().css({
      paddingTop: $container.data("origHeight"),
    })

    // don't do any animation if list shorter than max
    if (multiplier > 1) {
      $container
        .css({
          height: maxHeight,
          overflow: "hidden",
        })
        .mousemove((e) => {
          const offset = $container.offset()
          const relativeY =
            (e.pageY - offset.top) * multiplier -
            $container.data("origHeight") * multiplier
          if (relativeY > $container.data("origHeight")) {
            $list.css("top", -relativeY + $container.data("origHeight"))
          }
        })
    }
  }

  //---- Hide mainnav dropdown
  function dropdownOut() {
    const $el = $(this)

    // put things back to normal
    $el
      .height($(this).data("origHeight"))
      .find("ul")
      .css({ top: 0 })
      .hide()
      .end()
      .find("a")
      .removeClass("hover")
  }

  // Do something at a certain breakpoint size
  // $(window).on('load resize', Foundation.utils.debounce(function() {
  //   if (window.matchMedia(Foundation.media_queries.large).matches) {
  //
  //   }
  // },200,true));
}
