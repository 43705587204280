function openVideo(el) {
  if (BigPicture) {
    const videoID = el.getAttribute("data-video")
    const args = {
      el,
      noLoader: true,
    }
    if (videoID.match(/^.*vimeo\.com\/\d+$/gi)) {
      args["vimeoSrc"] = videoID.split("/").reverse()[0]
    } else if (videoID.match(/^.*[(youtu\.be)(youtube\.com)]\/.+$/gi)) {
      args["ytSrc"] = videoID.split("/").reverse()[0]
    } else {
      args["vidSrc"] = videoID
    }
    BigPicture(args)
  }
}

export const addVideoListeners = (videos) => {
  videos.forEach((v) => {
    v.addEventListener("click", (e) => {
      e.preventDefault()
      openVideo(v)
    })
    v.addEventListener("touchend", (e) => {
      e.preventDefault()
      openVideo(v)
    })
  })
}
